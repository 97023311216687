@font-face {
    font-family: 'Bebas Neue';
    src: url('BebasNeueRegular.eot');
    src: url('BebasNeueRegular.eot?#iefix') format('embedded-opentype'),
        url('BebasNeueRegular.woff2') format('woff2'),
        url('BebasNeueRegular.woff') format('woff'),
        url('BebasNeueRegular.ttf') format('truetype'),
        url('BebasNeueRegular.svg#BebasNeueRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TCCC-UnityCondensed';
    src: url('TCCC-UnityCondensed-Medium.eot');
    src: url('TCCC-UnityCondensed-Medium.eot?#iefix') format('embedded-opentype'),
        url('TCCC-UnityCondensed-Medium.woff2') format('woff2'),
        url('TCCC-UnityCondensed-Medium.woff') format('woff'),
        url('TCCC-UnityCondensed-Medium.ttf') format('truetype'),
        url('TCCC-UnityCondensed-Medium.svg#TCCC-UnityCondensed-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue Book';
    src: url('BebasNeueBook.eot');
    src: url('BebasNeueBook.eot?#iefix') format('embedded-opentype'),
        url('BebasNeueBook.woff2') format('woff2'),
        url('BebasNeueBook.woff') format('woff'),
        url('BebasNeueBook.ttf') format('truetype'),
        url('BebasNeueBook.svg#BebasNeueBook') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TCCC-UnityCondensed';
    src: url('TCCC-UnityCondensed-Bold.eot');
    src: url('TCCC-UnityCondensed-Bold.eot?#iefix') format('embedded-opentype'),
        url('TCCC-UnityCondensed-Bold.woff2') format('woff2'),
        url('TCCC-UnityCondensed-Bold.woff') format('woff'),
        url('TCCC-UnityCondensed-Bold.ttf') format('truetype'),
        url('TCCC-UnityCondensed-Bold.svg#TCCC-UnityCondensed-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

